<template>
  <div>
    <FormList account-type="account-balance-list"></FormList>
  </div>
</template>

<script>
import FormList from './components/FormList';
export default {
  name: '',
  components: { FormList },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {}
};
</script>

<style  scoped>
</style>