import { render, staticRenderFns } from "./account-balance-list.vue?vue&type=template&id=2ca24ca1&scoped=true"
import script from "./account-balance-list.vue?vue&type=script&lang=js"
export * from "./account-balance-list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca24ca1",
  null
  
)

export default component.exports